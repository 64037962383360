import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route} from "react-router-dom";
import queryString from 'query-string';
import axios from "axios";
import './index.css';

class RenderQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {pageIndex:this.props.pageIndex, callback : this.props.callback}
    this.onClickItem = this.onClickItem.bind(this);
  }

  onClickItem(e) {// はクリックしたオブジェクトで、イベントハンドラの設定したオブジェクトだけじゃなく、子が帰ってくる場合もある
    this.state.callback(e.currentTarget.value);
  }

  render() {
    console.log("RenderQuestions:"+this.props.pageIndex+","+this.state.pageIndex);
    const items = this.props.items;
    const listItems = items.map((item, index) =>
      <li key={index} value={index} onClick={this.onClickItem}>
        <input type='radio' ref={this.input} name='contents' id={'page'+item.id} checked={index===this.props.pageIndex} onChange={this.onClickItem}/>
        <label htmlFor={'page'+item.id}></label>
        <span className="toc-content">（{item.id}）{item.text}</span>
      </li>
    );
    console.log(items);
    var hgt = (49*(items.length+1))+'px';
    return (
      <ul style={{height:hgt}}>
        {listItems}
      </ul>
    );
  }
}

class RenderCarousel extends React.Component {
  constructor( props ){
    super( props ) ;
    this.state = { callback : this.props.callback, pageIndex: this.props.pageIndex} ;
    this.onClickPrev = this.onClickPrev.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.refItems = props.items.map(() => React.createRef());
  }

  onClickPrev(e) {
   console.log("onClickPrev");
    this.state.callback(-1); // カレントのページ番号を何処かから取得
  }
  onClickNext(e) {
    console.log("onClickNext");
    this.state.callback(1);// カレントのページ番号を何処かから取得
  }
   
  // スライドアニメーション中か
  isAnimateing(){
    for(var i=0; i < this.refItems.length; ++i){
      if(this.refItems[i].current.classList.contains('carousel-item-next')){
        return true;
      }
      if(this.refItems[i].current.classList.contains('carousel-item-prev')){
        return true;
      }
    }
    return false;
  }

  render() {
    console.log("RenderCarousel:"+this.props.pageIndex+"/"+this.state.pageIndex);
    const items = this.props.items;

    var listItems = items.map((item, index) =>{
      var className = index===this.props.pageIndex ? 'carousel-item active' : 'carousel-item';  
      console.log(index+"/"+this.props.pageIndex+":"+className);
      return (<div ref={this.refItems[index]} key={index} value={index} className={className}> 
        <img className="d-block w-100" src={'resource/slide/cp'+this.props.chapter+'/'+this.props.json_name+'/'+item.image} alt="slide"/>
      </div>);
    });
    console.log(items);
    return (
      <div className="carousel-content">
        <div className="carousel-inner">
          {listItems}
        </div>
        <a className="carousel-control-prev arrow-left" onClick={this.onClickPrev} data-slide="prev"> 
            <span className="sr-only">Previous</span>
        </a> 
        <a className="carousel-control-next arrow-right" onClick={this.onClickNext} data-slide="next">
            <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}


class RenderContent extends React.Component {
  constructor( props ){
    super( props ) ;
    this.state = {
       selectedIndex : 0,
       hide: false,
    };
    this.onChangedTocPage = this.onChangedTocPage.bind(this);
    this.onChangedImagePage = this.onChangedImagePage.bind(this);
    this.onClickToggle = this.onClickToggle.bind(this);
    //this.onLayout = this.onLayout.bind(this);
    this.refCurousel = React.createRef();
    this.refImagePage = React.createRef();
    this.refTocPage = React.createRef();
    this.refSideBar = React.createRef();
    this.refSlide = React.createRef();
    //useResize(this.refSlide);
  }

  onChangedTocPage( idx ){
    console.log("RenderGoToIndex: Index:"+idx);
    this.setState( { selectedIndex : idx } ) ;
  }


  onLayout = (e) => {
    /* コンポーネントの高さを取得し、stateに保存 */
    //this.setState({
    //  height: e.nativeEvent.layout.height
    //});
    console.log("==============");
    console.log(e.nativeEvent.layout.height);

  }
  // 相対変化
  onChangedImagePage( add ){
    if(this.refImagePage.current.isAnimateing()){
      return ;
    }
    var idx = this.state.selectedIndex + add;
    console.log("RenderAddIndex: Index:"+ idx);
    if(idx<0){
      idx = 0;
    }
    if(idx >= this.props.items.length){
      idx = this.props.items.length -1;
    }
    this.setState( { selectedIndex : idx} ) ;
  }

  onClickToggle(e){
    console.log("toggle");
    e.preventDefault();
    this.setState( { hide : !this.state.hide} ) ;
  }

  componentDidMount() {
  //componentDidUpdate(){
    if(this.refSlide){
      const height = this.refSlide.offsetHeight;
      //this.setState({ height });
      console.log("slide:"+height);
      //console.log("slide:"+this.refSlide.layout.height);
      
    }else{
      console.log("mount");  
    }
  }
  componentDidUpdate() {
    console.log("update");  
  }

  render() {
    var hide = "content-block";
    var cls = "sidebar col-sm-12 col-md-12 col-lg-4 ";
    if(this.state.hide){
      cls += this.state.hide?'hide':'';
      hide = this.state.hide?'hide':'';//todo: hide指定は１ヶ所にしたい
    }

    return (
      <div id="content-block" className={hide}>
        <div id="slide" ref={ (refSlide) => { this.refSlide = refSlide } } className="slide col-sm-12 col-md-12 col-lg-8" >
            <div id="carousel-main" ref={this.refCurousel} className="carousel slide" data-wrap="false" data-interval="false" data-ride="carousel"> 
               <RenderCarousel ref={this.refImagePage} chapter={this.props.chapter} json_name={this.props.json_name} items={this.props.items} pageIndex={this.state.selectedIndex} callback={this.onChangedImagePage}/>
            </div>
        </div>
        <div id="sidebar-wrapper" className={cls}>
            <div className="sidebar-title">              
                <h4 id="toc-title" className="sidebar-toc" style={{color: '#31353A',paddingTop:'2px'}}>もくじ</h4>
                <h4 id="toc-index" className="sidebar-toc" style={{color: '#62686F',paddingTop:'2px'}}>{this.state.selectedIndex+1}/{this.props.items.length}</h4>
                <p id='menu-toggle' className="sidebar-toc" style={{paddingLeft:'13px', paddingRight:'8px'}} onClick={this.onClickToggle} >
                  <input type="image" src="img/btn_sidebar.png" width="14px" height="13px"/>
                </p>
            </div>
            <div className="contents">
                <RenderQuestions ref={this.refTocPage} items={this.props.items} pageIndex={this.state.selectedIndex} callback={this.onChangedTocPage}/>
            </div>
        </div>

        <span className="wrapper-footer">
          <footer>
              <div align="left" className="footer-text">© 2021 Life is Tech, Inc</div>
          </footer>
        </span>
      </div>
    );

  }
}


class RenderMain extends React.Component {
  constructor( props ){
    super( props ) ;
    this.state = {
       selectedIndex : 0,
       hide: false,
    };
  }

  getChapter(chapter){
    for(var i=0; i < this.props.items.length;++i){
      if(this.props.items[i].chapter === chapter){
        return this.props.items[i];
      }
    }
    return null;
  }

  render() {
    var chapter = parseInt(this.props.qs["chapter"]);
    var sectionIndex = parseInt(this.props.qs["section"]);
    var question = parseInt(this.props.qs["question"]);
    var noIndex = parseInt(this.props.qs["no"]);
    if (isNaN(question)) {
      question = 0;
    }
    if (isNaN(noIndex)) {
      noIndex = 0;
    }

    if (isNaN(sectionIndex)||isNaN(chapter)) {
    // sectionとchapterの指定がなければTOC表示
      var links=[];
      var count = 0;
      const chapterItems = this.props.items.map((item, index) => {
        /*
        const sectionItems = Object.keys(item.secions).map((section, idx) => {
          return <div key={count++} >
            <a href={'?chapter='+item.chapter+"&section="+section} >{item.name}: Section{section}</a>
          </div>
        });
        links = links.concat(sectionItems);
        */

        const sectionItems = Object.keys(item.secions).map((section, idx) => {
          const questionItems = Object.keys(item.secions[section]).map((question, idx) => {
            const noItems = Object.keys(item.secions[section][question]).map((no, idx) => {
              return <div key={count++} >
                <a href={'?chapter='+item.chapter+"&section="+section+"&question="+question+"&no="+no} >{item.name}: Section{section} Question{question} No{no}</a>
              </div>
            });
            links = links.concat(noItems);
          });
        });

      });
      return (links)
    }else{
      var chapterData = this.getChapter(chapter);
      if(chapterData==null){
        chapterData = this.props.items[0];
      }
      var json_name = chapterData.secions[sectionIndex][question][noIndex];
  
      console.log("section:"+sectionIndex+","+json_name);
      axios.get("resource/json/cp"+chapter+"/"+json_name+".json").then(res => {
        console.log(res.data);
        var values = [];
        for(var i=0;i<res.data.questions.length;++i){
          var q = res.data.questions[i];
          values.push({id:i+1, text:q.title, image:q.image });
        }
        ReactDOM.render(
            <RenderContent chapter={chapter} json_name={json_name} question={question} no={noIndex} items={values}/>,
            document.getElementById('question-content'));   
      });
      return (<div id="question-content"></div>);
    }

  }

}


axios.get("resource/index.json").then(res => {
  //console.log(JSON.parse(res.data));
  ReactDOM.render(
    <Router>
      <Route render={ (props) => 
        <RenderMain qs={queryString.parse(props.location.search)} items={res.data.contents}/>
      }/>
      </Router>,
      document.getElementById('main'));
});



